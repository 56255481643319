import { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import GitHubLogin from 'react-github-login';
import Typewriter from 'typewriter-effect/dist/core';
import { hri } from 'human-readable-ids';
import cx from 'classnames';
import { format } from 'date-fns'
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss'
import { ReactComponent as Logo } from './assets/images/logo.svg';
import 'react-toastify/dist/ReactToastify.css';
import config from './config';
import './_app.scss';

const RANDOM_USERNAMES = () => [...new Array(99)].map(() => hri.random());

let tw, globalFocus, globalUn, globalVisible;

function Search({ onClose, visible }) {
  const [username, setUsername] = useState('');
  const [focused, setFocused] = useState(false);
  const [loading, setLoading] = useState(false);

  globalVisible = visible;

  useEffect(() => {
    globalFocus = focused;
    globalUn = username;
  }, [focused, username]);

  useEffect(() => {
    if (!visible && tw) { tw.stop(); }

    if (!visible) {
      document.getElementById('search-input').value = '';

      return void setUsername('');
    }

    startTypeWriting();
  }, [visible])

  const startTypeWriting = async () => {
    await new Promise(resolve => setTimeout(resolve, 2000));

    if (globalFocus || globalUn || !globalVisible) { return; }

    tw = new Typewriter('#gh-un', {
      strings: RANDOM_USERNAMES(),
      loop: true,
      autoStart: true
    });
  };

  const setFocus = () => {
    if (tw) { tw.stop(); }

    setFocused(true);
  }

  const setBlur = () => {
    setFocused(false);

    startTypeWriting();
  }

  const handleUnChange = ({ target: { value } }) => {
    setUsername(value);
  }

  const onSearch = async () => {
    setLoading(true);

    try {
      await axios.post(`${config.serverUrl}/exists`, { username });

      setLoading(false);

      Swal.fire(
        'We\'ve checked.',
        `${username} has never accessed the solutions.`,
        'info'
      );
    } catch(e) {
      setLoading(false);

      if (!e.response || e.response.status !== 409) {
        return void toast("Oops, something went wrong.", { type: 'error', position: "bottom-left", hideProgressBar: true });
      }

      Swal.fire(
        'We\'ve checked.',
        `${username} first accessed the solutions on ${format(new Date(e.response.data), 'dd/mm/yy')}.`,
        'success'
      );
    }
  }

  return (
    <div className={cx('search', { 'search--visible': visible })}>
      <div className="search__header">
        <button className="search__close" onClick={onClose}>
          <i className="fas fa-times"></i>
        </button>
      </div>
      <div className="search__content">
        <h1 className="title is-size-1-tablet is-size-1-desktop is-size-3-mobile has-text-centered mb-2 mt-0">Let's see if they've been in the solutions 🧐</h1>
        <div className="mt-6 search__entry">
          <p className="heading has-text-centered">What's their GitHub username?</p>
          <div className="search__input mt-2 mb-4">
            <input className="input" id="search-input" onFocus={setFocus} onBlur={setBlur} onChange={handleUnChange} />
            {!username && !focused && visible ? <p className="search__tw" id="gh-un"/> : null}
          </div>
        </div>
        <button disabled={!username} className={`button search__button mt-6 ${loading ? 'is-loading' : ''}`} onClick={onSearch}>
          <span>Let's Go!</span>
        </button>
      </div>
    </div>
  );
}

function App() {
  const [joined, setJoined] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alreadyIn, setAlreadyIn] = useState(false);
  const [searching, toggleSearch] = useState(false);

  if (window.location.search.includes('login=true')) { return null; }

  const joinRepo = async ({ code }) => {
    setLoading(true);

    try {
      await axios.post(config.serverUrl, { code });

      setJoined(true);
    } catch(e) {
      setLoading(false);

      if (e.response && e.response.status && e.response.status === 409) {
        setAlreadyIn(true);

        return void setJoined(true);
      }

      toast("Oops, something went wrong.", { type: 'error', position: "bottom-left", hideProgressBar: true })
    }
  }

  const onFailure = () => {
    toast("Oops, something went wrong.", { type: 'error', position: "bottom-left", hideProgressBar: true })
  }

  const handleToggleSearch = () => {
    toggleSearch(!searching);
  }

  return (
    <div className="app">
      <ToastContainer />
      <Search visible={searching} onClose={handleToggleSearch} />
      <div className="app__social">
        <button className="app__search" onClick={handleToggleSearch}>
          <i className="fas fa-search"></i>
        </button>
        <a className="icon" href="https://twitter.com/alexgurr" target="_blank" rel="noreferrer">
          <i className="fab fa-twitter"></i>
        </a>
        <a className="icon" href="https://github.com/alexgurr" target="_blank" rel="noreferrer">
          <i className="fab fa-github"></i>
        </a>
        <a className="icon" href="https://dev.to/alexgurr" target="_blank" rel="noreferrer">
          <i className="fab fa-dev"></i>
        </a>
      </div>
      {
        !joined
          ? (
            <>
              <Logo className="app__logo mb-6" />
              <h1 className="title is-size-1-tablet is-size-1-desktop is-size-3-mobile has-text-centered mb-2 mt-6">We knew you'd show up! Let's <span>jump in</span>. 👇</h1>
              <GitHubLogin
                clientId="1a6b06e03029a316d0b8"
                onSuccess={joinRepo}
                onFailure={onFailure}
                scope="read:user"
                redirectUri=""
                className={`button animate__animated animate__pulse animate__infinite animate__slow mt-6 mb-6 is-light ${loading ? 'is-loading' : ''}`}
                buttonText="Let Me In"
              >
                <div>
                  <span className="icon">
                    <i className="fab fa-github"></i>
                  </span>
                  <span>Let Me In</span>
                </div>
              </GitHubLogin>
              <p className="mt-6 title is-size-6 mb-1 has-text-centered">Why do you need me to sign in with GitHub?</p>
              <p className="mt-0 mb-0 is-size-7 has-text-centered">We do this to prevent spam. The only information we use is your GitHub username to invite you.</p>
            </>
          )
          : (
            <div className="app__confirm">
              <h1 className="title is-size-2-tablet is-size-2-desktop is-size-4-mobile has-text-centered m6-6">
                {alreadyIn
                  ? 'You\'re already in and ready to go!'
                  : 'That\'s it, you\'re in! We\'ve dropped you a GitHub invite.'
                }
              </h1>
              <h1 className="title is-size-3-tablet is-size-3-desktop is-size-5-mobile has-text-centered m6-6">Jump in to the repository <a href="https://github.com/alexgurr/react-coding-solutions">here</a>.</h1>
            </div>
          )
      }
    </div>
  );
}

export default App;
